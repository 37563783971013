export default {
    methods: {
      async getGrcControlSummary(params) {
        const options = {
          method: "GET",
          url: `${process.env.VUE_APP_BASEURL}/dashboard/primary-standard-control-summary`,
          params: params,
        };
        return await this.$http(options);
      },
  
      async getGrcRiskPostureSummary(params) {
        const options = {
          method: "GET",
          url: `${process.env.VUE_APP_BASEURL}/dashboard/risk-posture-summary`,
          params: params,
        };
        return await this.$http(options);
      },
  
      async getGrcLegalRegisterSummary(params) {
        const options = {
          method: "GET",
          url: `${process.env.VUE_APP_BASEURL}/dashboard/legal-register-summary`,
          params: params,
        };
        return await this.$http(options);
      },
  
      async getGrcHighRiskSummary(params) {
        const options = {
          method: "GET",
          url: `${process.env.VUE_APP_BASEURL}/dashboard/asset-risk-summary`,
          params: params,
        };
        return await this.$http(options);
      },
    },
  };
  