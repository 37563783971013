<template>
  <b-tabs justified>
    <b-tab lazy>
      <template #title>
        <feather-icon icon="BarChart2Icon" />
        <span>General</span>
      </template>
      <general-dashboard />
    </b-tab>
    <b-tab lazy>
      <template #title>
        <feather-icon icon="TrendingUpIcon" />
        <span>Gap Assessment</span>
      </template>
      <gap-assessment-section />
    </b-tab>

    <b-tab lazy>
      <template #title>
        <feather-icon icon="AlertOctagonIcon" />
        <span>Risk Assessment</span>
      </template>
      <risk-assessment-section />
    </b-tab>

    <!-- <b-tab lazy>
      <template #title>
        <feather-icon icon="FilePlusIcon" />
        <span>Tasks</span>
      </template>
    </b-tab> -->
  </b-tabs>
</template>

<script>
import { BTabs, BTab, BCardText } from "bootstrap-vue";
import GapAssessmentSection from "./sections/GapAssessment/GapAssessmentSection.vue";
import RiskAssessmentSection from "./sections/RiskAssessment/RiskAssessmentSection.vue";
import GeneralDashboard from "./sections/GeneralDashboard/GeneralDashboard.vue";
export default {
  components: {
    BCardText,
    BTabs,
    BTab,
    GapAssessmentSection,
    RiskAssessmentSection,
    GeneralDashboard,
  },
};
</script>
 
Position
