<template>
  <div class="card">
    <b-overlay
      variant="white"
      :show="showOverlay"
      spinner-variant="primary"
      blur="0"
      opacity=".75"
      rounded="sm"
    >
      <div class="w-100">
        <swiper
          v-if="summaries && summaries.length > 0"
          class="swiper-progress w-100"
          :options="swiperOptions"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
        >
          <swiper-slide v-for="(data, index) in summaries" :key="index">
            <radial-assessment-chart
              :id="data._id"
              :index="index + 1"
              :series="[
                Math.round(data.completion * 100) / 100,
                Math.round(data.gap * 100) / 100,
              ]"
              :assessmentName="data.title"
            />
          </swiper-slide>

          <div slot="button-next" class="swiper-button-next" />
          <div slot="button-prev" class="swiper-button-prev" />
          <div slot="pagination" class="swiper-pagination" />
        </swiper>
        <div
          v-else
          class="w-100 h-auto pt-3 d-flex flex-column align-items-center justify-content-center"
          style="height: 40vh"
        >
          <feather-icon icon="DatabaseIcon" size="38" class="mb-75" />
          <h3 class="text-center font-weight-bold">No Completed Assessments</h3>
          <p style="width: 60%" class="text-center">
            It seems like there are no completed gap assessments at the moment.
          </p>
        </div>
      </div>
    </b-overlay>
  </div>
</template>

<script>
import DashboardGapAssessmentMixins from "@/mixins/DashboardGapAssessmentMixins";
import RadialAssessmentChart from "../../components/RadialAssessmentChart.vue";
import ResponseMixins from "@/mixins/ResponseMixins";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
import { BOverlay } from "bootstrap-vue";
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";

import { ref } from "vue";
import { Controller } from "swiper";

import "@core/scss/base/pages/swiper.scss";
// import "@core/scss/base/plugins/extensions/ext-component-swiper.scss"

export default {
  components: {
    RadialAssessmentChart,
    Swiper,
    SwiperSlide,
    FeatherIcon,
    BOverlay,
  },
  mixins: [DashboardGapAssessmentMixins, ResponseMixins],

  data() {
    return {
      filters: {
        dont_paginate: 1,
        status: 2,
      },
      total: 1,
      showOverlay: false,
      summaries: [],

      swiperOptions: {
        slidesPerView: 3,
        spaceBetween: 0,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
      },
    };
  },

  mounted() {
    this.load();
  },

  methods: {
    load() {
      this.getGapAssessmentsSummaryAndSetData({ status: 2, dont_paginate: 1 });
    },

    handleSlideChange(slide) {
      console.log("Slide", slide);
    },

    // handleSwiperEnd() {
    //   if (this.filters.limit < this.total) {
    //     this.$set(this.filters, "limit", this.filters.limit + 2);
    //     this.getGapAssessmentsSummaryAndSetData(this.filters);
    //   }
    // },

    getGapAssessmentsSummaryAndSetData(params) {
      this.showOverlay = true;
      this.getGapAssessmentsSummary(params)
        .then((res) => {
          this.summaries = res.data.data;
          console.log(res);
        })
        .catch((err) => {
          this.handleError(err);
          console.log(err);
        })
        .finally(() => {
          this.showOverlay = false;
        });
    },
  },
};
</script>

<style lang="scss">
</style>