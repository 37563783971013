var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"position-relative table-responsive",staticStyle:{"min-height":"30vh"}},[_c('table',{staticClass:"table b-table table-bordered table-hover",attrs:{"role":"table"}},[_vm._m(0),_c('tbody',{attrs:{"role":"rowgroup"}},[(_vm.risks && _vm.risks.length > 0)?[_vm._l((_vm.risks),function(risk,index){return _c('tr',{key:risk._id,on:{"click":function () {
            _vm.$router.push({
              path: '/risk-register',
              query: Object.assign({}, _vm.query, {risk_id:risk._id, search:risk.name}),
            });
          }}},[_c('td',{staticStyle:{"width":"40px"}},[_vm._v(_vm._s(index + 1))]),_c('td',[_vm._v(_vm._s(risk.name))])])}),_c('tr',[_c('td',{attrs:{"colspan":"2"}},[_c('div',{staticClass:"w-100 d-flex align-items-center justify-content-end"},[_c('b-button',{attrs:{"size":"sm","variant":"primary"},on:{"click":function () {
                    _vm.$router.push({
                      path: '/risk-register',
                      query: _vm.query,
                    });
                  }}},[_vm._v("View More")])],1)])])]:[_c('empty-table-section',{attrs:{"title":"Risks Not Found"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('p',{staticClass:"text-center"},[_vm._v(" It seems like there are no risks of this type at the moment. ")])]},proxy:true}])})]],2)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',{attrs:{"role":"rowgroup"}},[_c('tr',{attrs:{"role":"row"}},[_c('th',{attrs:{"role":"columnheader","scope":"col"}},[_vm._v("#")]),_c('th',{attrs:{"role":"columnheader","scope":"col"}},[_vm._v("Name")])])])}]

export { render, staticRenderFns }