<template>
  <div
    class="w-100 d-flex flex-column align-items-center justify-content-start"
  >
    <div class="w-100 d-flex align-items-center justify-content-end mb-0">
      <div class="mr-1" v-if="tenantModules.includes('GRC')">
        <b-button
          variant="primary"
          pill
          @click="
            () => {
              $router.push('/review-controls');
            }
          "
        >
          <span
            class="align-middle text-center d-flex align-items-center justify-content-between"
          >
            <span>Review your controls</span>
            <feather-icon icon="ArrowRightCircleIcon" class="ml-2" size="24" />
          </span>
        </b-button>
      </div>

      <div class="mr-1" v-if="tenantModules.includes('GRC')">
        <b-button
          variant="primary"
          pill
          @click="
            () => {
              $router.push('/gapassessment');
            }
          "
        >
          <span
            class="align-middle text-center d-flex align-items-center justify-content-between"
          >
            <span>Perform a Gap Assessment</span>
            <feather-icon icon="ArrowRightCircleIcon" class="ml-2" size="24" />
          </span>
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import TileType1 from "../../components/TileType1.vue";
import { BButton } from "bootstrap-vue";
import ResponseMixins from "@/mixins/ResponseMixins";
import GeneralDashboardMixins from "@/mixins/GeneralDashboardMixins";

export default {
  mixins: [ResponseMixins, GeneralDashboardMixins],
  components: { TileType1, BButton },

  data() {
    return {
      showOverlay: false,

      chartData: {
        exposedAssets: 0,
        openVulns: 0,
        insecurePorts: 0,
        expiredCertificates: 0,
        breachedEmails: 0,
      },
    };
  },

  computed: {
    tenantModules() {
      return this.$store.state.app.currentWorkspace.enabled_modules.map(
        (m) => m.name
      );
    },
  },

  mounted() {
  },

  methods: {
   
  },
};
</script>

<style></style>
