<template>
  <div class="d-flex align-items-start justify-content-between w-100">
    <div class="card" style="width: 49%">
      <div class="card-header pb-75">
        <h4 class="mb-0 font-weight-bold">Major Vulnerabilities</h4>
      </div>

      <div
        class="position-relative table-responsive"
        style="height: 35vh; overflow-y: auto"
      >
        <table role="table" class="table b-table table-striped">
          <thead role="rowgroup">
            <tr role="row">
              <th role="columnheader" scope="col">#</th>
              <th role="columnheader" scope="col">Name</th>
            </tr>
          </thead>
          <tbody role="rowgroup">
            <template
              v-if="
                summary.vulnerabilities &&
                Object.keys(summary.vulnerabilities).length > 0
              "
            >
              <tr
                v-for="(sum, key, index) in summary.vulnerabilities"
                :key="key"
              >
                <td style="width: 40px">{{ index + 1 }}</td>
                <td>{{ sum.name }}</td>
              </tr>
            </template>

            <empty-table-section v-else title="Vulnerabilities Not Found">
              <template #content>
                <p class="text-center">
                  It seems like there are no major vulnerabilities at the
                  moment.
                </p>
              </template>
            </empty-table-section>
          </tbody>
        </table>
      </div>
    </div>

    <div class="card" style="width: 49%">
      <div class="card-header pb-75">
        <h4 class="mb-0 font-weight-bold">Major Threats</h4>
      </div>

      <div
        class="position-relative table-responsive"
        style="height: 35vh; overflow-y: auto"
      >
        <table role="table" class="table b-table table-striped">
          <thead role="rowgroup">
            <tr role="row">
              <th role="columnheader" scope="col">#</th>
              <th role="columnheader" scope="col">Name</th>
            </tr>
          </thead>
          <tbody role="rowgroup">
            <template
              v-if="summary.threats && Object.keys(summary.threats).length > 0"
            >
              <tr v-for="(sum, key, index) in summary.threats" :key="key">
                <td style="width: 40px">{{ index + 1 }}</td>
                <td>{{ sum.name }}</td>
              </tr>
            </template>

            <empty-table-section v-else title="Threats Not Found">
              <template #content>
                <p class="text-center">
                  It seems like there are no major threats at the moment.
                </p>
              </template>
            </empty-table-section>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import ResponseMixins from "@/mixins/ResponseMixins";
import DashboardRiskAssessmentMixins from "@/mixins/DashboardRiskAssessmentMixins";
import EmptyTableSection from "@/components/EmptyTableSection.vue";
export default {
  components: { EmptyTableSection },
  mixins: [ResponseMixins, DashboardRiskAssessmentMixins],

  data() {
    return {
      summary: [],
    };
  },

  mounted() {
    this.load();
  },

  methods: {
    load() {
      this.getMajorVulnerabilityOrThreatSummaryAndSetData();
    },

    getMajorVulnerabilityOrThreatSummaryAndSetData(params) {
      this.getMajorVulnerabilityOrThreatSummary(params)
        .then((res) => {
          console.log("t/v", res.data.data);
          this.summary = res.data.data;
        })
        .catch((err) => {
          console.log(err);
          this.handleResponse(err);
        })
        .finally(() => {});
    },
  },
};
</script>

<style>
</style>