<template>
  <div class="card mb-0">
    <b-tabs pills card>
      <b-tab lazy>
        <template #title>
          <feather-icon icon="RotateCwIcon" />
          <span>Ongoing Assessments</span>
        </template>
        <ongoing />
      </b-tab>

      <b-tab lazy>
        <template #title>
          <feather-icon icon="CheckCircleIcon" />
          <span>Completed Assessments</span>
        </template>
        <completed />
      </b-tab>
    </b-tabs>

    <div class="card">
      <div class="card-header pt-0 pb-50">
        <h3 class="mb-0 font-weight-bold">My Tasks</h3>
      </div>

      <b-tabs pills nav-wrapper-class="mx-1">
        <b-tab lazy>
          <template #title>
            <feather-icon icon="LayersIcon" />
            <span>Open</span>
          </template>
          <open />
        </b-tab>

        <b-tab lazy>
          <template #title>
            <feather-icon icon="SlashIcon" />
            <span>Closed</span>
          </template>
          <closed />
        </b-tab>
      </b-tabs>
    </div>
  </div>
</template>

<script>
import DashboardGapAssessmentMixins from "@/mixins/DashboardGapAssessmentMixins";
import ResponseMixins from "@/mixins/ResponseMixins";
import { BTabs, BTab } from "bootstrap-vue";
import Ongoing from "./Ongoing.vue";
import Completed from "./Completed.vue";
import Open from "../Tasks/Open.vue";
import Closed from "../Tasks/Closed.vue";

export default {
  components: {
    BTabs,
    BTab,
    Ongoing,
    Completed,
    Open,
    Closed,
  },
  mixins: [DashboardGapAssessmentMixins, ResponseMixins],

  data() {
    return {};
  },

  mounted() {},

  methods: {},
};
</script>

<style lang="scss">
</style>