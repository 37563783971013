<template>
  <b-overlay
    opacity="0.6"
    blur="1rem"
    :show="showOverlay"
    rounded="md"
    variant="secondary"
  >
    <div
      class="d-flex flex-column align-items-center justify-content-center flex-wrap rounded p-1 text-white cursor-pointer tile"
      style="min-width: 243px; height: 80px"
      :style="{ 'background-color': bgColor }"
    >
      <h4 class="font-weight-bold text-truncate mb-25 text-white">
        {{ quantity }}
      </h4>
      <p class="mb-0">{{ title }}</p>
    </div>
  </b-overlay>
</template>

<script>
import { BOverlay } from "bootstrap-vue";

export default {
  components: {
    BOverlay,
  },
  props: {
    title: {
      type: String,
      required: true,
    },

    quantity: {
      type: Number,
      required: true,
    },

    bgColor: {
      type: String,
      required: true,
    },

    showOverlay: {
      type: Boolean,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.tile {
  transition: all 0.35s ease-in-out;

  &:hover {
    transform: scale(1.1);
    box-shadow: rgba(102, 16, 242, 0.35) 0px 5px 15px 0px;
  }
}
</style>
