<template>
  <b-overlay
    :show="showOverlay"
    opacity="0.17"
    blur="1rem"
    rounded="md"
    variant="secondary"
  >
    <div class="card mb-0 cursor-pointer">
      <div class="card-header">
        <h2 class="card-title">Control Distribution</h2>
      </div>
      <div class="card-body" style="height: 380px">
        <template v-if="totalValue === 0">
          <empty-section title="Data Empty">
            <template #content>
              <div
                class="w-100 d-flex flex-column align-items-center justify-content-center"
              >
                <p class="font-weight-bold text-center">
                  It seems like there are no Data at the moment.
                </p>
                <b-button
                  @click="
                    () => {
                      $router.push('/review-controls');
                    }
                  "
                  variant="success"
                  class="mt-50"
                  size="sm"
                  ><span class="mr-50">Review Controls</span
                  ><feather-icon icon="ArrowRightCircleIcon"
                /></b-button>
              </div>
            </template>
          </empty-section>
        </template>
        <template v-else>
          <vue-apex-charts
            type="donut"
            height="350"
            :options="donutChart.chartOptions"
            :series="series"
          />
        </template>
      </div>
    </div>
  </b-overlay>
</template>

<script>
import { BOverlay, BButton } from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import EmptySection from "../../components/EmptySection.vue";
import ResponseMixins from '@/mixins/ResponseMixins';
import GeneralDashboardMixins from '@/mixins/GeneralDashboardMixins';

const chartColors = {
  column: {
    series1: "#826af9",
    series2: "#d2b0ff",
    bg: "#f8d3ff",
  },
  success: {
    shade_100: "#7eefc7",
    shade_200: "#06774f",
  },
  donut: {
    series1: "#ffe700",
    series2: "#00d4bd",
    series3: "#826bf8",
    series4: "#2b9bf4",
    series5: "#A020F0",
  },
  area: {
    series3: "#a4f8cd",
    series2: "#60f2ca",
    series1: "#2bdac7",
  },
};

export default {
  components: { VueApexCharts, BOverlay, EmptySection, BButton },
  mixins: [ResponseMixins, GeneralDashboardMixins],
  data() {
    return {
      showOverlay: false,
      totalValue: 0,
      series: [],
      donutChart: {
        chartOptions: {
          legend: {
            show: true,
            position: "bottom",
            fontSize: "14px",
            fontFamily: "Montserrat",
          },
          colors: [
            chartColors.donut.series1,
            chartColors.donut.series5,
            chartColors.donut.series3,
            chartColors.donut.series2,
          ],
          dataLabels: {
            enabled: true,
            formatter(val) {
              return `${parseInt(val)}%`;
            },
          },
          plotOptions: {
            pie: {
              donut: {
                labels: {
                  show: true,
                  name: {
                    fontSize: "2rem",
                    fontFamily: "Montserrat",
                  },
                  value: {
                    fontSize: "1rem",
                    fontFamily: "Montserrat",
                    formatter(val) {
                      return parseInt(val); // Removing percentage symbol
                    },
                  },
                  total: {
                    show: true,
                    fontSize: "1.5rem",
                    label: "Total",
                    formatter: function () {
                      return `${this.totalValue}`;
                    }.bind(this),
                  },
                },
              },
            },
          },
          labels: ["Implemented", "Not Implemented", "Partially Implemented", "Planned"],
          responsive: [
            {
              breakpoint: 992,
              options: {
                chart: {
                  height: 380,
                },
                legend: {
                  position: "bottom",
                },
              },
            },
            {
              breakpoint: 576,
              options: {
                chart: {
                  height: 320,
                },
                plotOptions: {
                  pie: {
                    donut: {
                      labels: {
                        show: true,
                        name: {
                          fontSize: "1.5rem",
                        },
                        value: {
                          fontSize: "1rem",
                        },
                        total: {
                          fontSize: "1.5rem",
                        },
                      },
                    },
                  },
                },
                legend: {
                  show: true,
                },
              },
            },
          ],
          chart: {
            events: {
              dataPointSelection: (event, chartContext, config) => {
                this.handleSectionClick(config);
              },
            },
          },
        },
      },
    };
  },
  mounted() {
    this.load();
  },
  methods: {
    load() {
      this.getGrcControlSummaryAndSetData({});
    },
    getGrcControlSummaryAndSetData(params) {
      this.showOverlay = true;
      this.getGrcControlSummary(params)
        .then((res) => {
          console.log("Control Summary: ", res.data.data);
          const cData = res.data.data;
          this.series = [
            cData.implemented,
            cData.not_implemented,
            cData.partially_implemented,
            cData.planned,
          ];
          this.totalValue = cData.total;
        })
        .catch((err) => {
          console.error(err);
          this.handleError(err);
        })
        .finally(() => {
          this.showOverlay = false;
        });
    },
    handleSectionClick(config) {
      const clickedLabel = this.donutChart.chartOptions.labels[config.dataPointIndex];
      console.log(`Clicked on section: ${clickedLabel}`);

      // Handle different sections based on the label
      switch (clickedLabel) {
        case "Implemented":
          this.$router.push("/review-controls?selectedAction=1");
          break;
        case "Not Implemented":
          this.$router.push("/review-controls?selectedAction=3");
          break;
        case "Partially Implemented":
          this.$router.push("/review-controls?selectedAction=2");
          break;
        case "Planned":
          this.$router.push("/review-controls?selectedAction=4");
          break;
        default:
          break;
      }
    },
  },
};
</script>

<style scoped>
.cursor-pointer {
  cursor: pointer;
}
</style>
