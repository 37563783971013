<template>
  <b-overlay
    opacity="0.17"
    blur="1rem"
    :show="showOverlay"
    rounded="md"
    variant="secondary"
  >
    <div class="card mb-0">
      <div class="card-header">
        <h2 class="card-title">Legal Register</h2>
      </div>
      <div class="card-body cursor-pointer" style="height: 380px">
        <template v-if="totalValue === 0">
          <empty-section title="Data Empty">
            <template #content>
              <div
                class="w-100 d-flex flex-column align-items-center justify-content-center"
              >
                <p class="font-weight-bold text-center">
                  It seems like there are no Data at the moment.
                </p>
                <b-button
                  @click="
                    () => {
                      $router.push('/legalregister');
                    }
                  "
                  variant="success"
                  class="mt-50"
                  size="sm"
                  ><span class="mr-50">Go to Legal Register</span
                  ><feather-icon icon="ArrowRightCircleIcon"
                /></b-button>
              </div>
            </template>
          </empty-section>
        </template>
        <template v-else>
          <vue-apex-charts
            type="radialBar"
            height="375"
            :options="radialBarChart.chartOptions"
            :series="series"
            @click="
              () => {
                $router.push('/legalregister');
              }
            "
          />
        </template>
      </div>
    </div>
  </b-overlay>
</template>

<script>
import { BOverlay, BButton } from "bootstrap-vue";

import VueApexCharts from "vue-apexcharts";
import EmptySection from "../../components/EmptySection.vue";
import ResponseMixins from '@/mixins/ResponseMixins';
import GeneralDashboardMixins from '@/mixins/GeneralDashboardMixins';

const chartColors = {
  column: {
    series1: "#826af9",
    series2: "#d2b0ff",
    bg: "#f8d3ff",
  },
  success: {
    shade_100: "#7eefc7",
    shade_200: "#06774f",
  },
  donut: {
    series1: "#ffe700",
    series2: "#00d4bd",
    series3: "#826bf8",
    series4: "#2b9bf4",
    series5: "#FFA1A1",
  },
  area: {
    series3: "#a4f8cd",
    series2: "#60f2ca",
    series1: "#2bdac7",
  },
};

export default {
  mixins: [ResponseMixins, GeneralDashboardMixins],
  components: { VueApexCharts, BOverlay, EmptySection, BButton },
  data() {
    return {
      showOverlay: false,
      series: [],
      total: 0,
      totalValue:0,
      radialBarChart: {
        series: [80, 50, 35],
        chartOptions: {
          colors: [
            chartColors.donut.series1,
            chartColors.donut.series2,
            chartColors.donut.series4,
          ],

          plotOptions: {
            radialBar: {
              size: 185,
              hollow: {
                size: "25%",
              },
              track: {
                margin: 15,
              },
              dataLabels: {
                name: {
                  fontSize: "2rem",
                  fontFamily: "Montserrat",
                },
                value: {
                  fontSize: "1rem",
                  fontFamily: "Montserrat",
                  formatter: function (val) {
                    return parseInt(val); // Removing percentage symbol
                  },
                },
                total: {
                  show: true,
                  fontSize: "1.2rem",
                  label: "Total",
                  formatter: function () {
                    return `${this.totalValue}`;
                  }.bind(this),
                },
              },
            },
          },
          legend: {
            show: true,
            position: "bottom",
            horizontalAlign: "center",
          },
          stroke: {
            lineCap: "round",
          },
          labels: ["Total", "Assessed", "Applicable"],
        },
      },
    };
  },

  mounted() {
    this.load();
  },

  methods: {
    load() {
      this.getGrcLegalRegisterSummaryAndSetData({});
    },

    getGrcLegalRegisterSummaryAndSetData(params) {
      this.showOverlay = true;
      this.getGrcLegalRegisterSummary(params)
        .then((res) => {
          console.log("LegalRegisters Summary: ", res.data.data);
          let cData = res.data.data;
          this.series = [
            cData.legal_register_count,
            cData.assessed_legal_register_count,
            cData.applicable_legal_register_count,
          ];
          this.totalValue = cData.legal_register_count;
        })
        .catch((err) => {
          console.log(err);
          this.handleError(err);
        })
        .finally(() => {
          this.showOverlay = false;
        });
    },
  },
};
</script>

<style></style>
