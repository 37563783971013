<template>
  <b-overlay
    opacity="0.17"
    blur="1rem"
    :show="showOverlay"
    rounded="md"
    variant="secondary"
  >
    <div class="card mb-0">
      <div class="card-header">
        <h2 class="card-title">Organization Risk Posture</h2>
      </div>
      <div class="card-body cursor-pointer" style="height: 380px">
        <template v-if="totalValue === 0">
          <empty-section title="Data Empty">
            <template #content>
              <div
                class="w-100 d-flex flex-column align-items-center justify-content-center"
              >
                <p class="font-weight-bold text-center">
                  It seems like there are no Data at the moment.
                </p>
                <b-button
                  @click="
                    () => {
                      $router.push('/assets/add');
                    }
                  "
                  variant="success"
                  class="mt-50"
                  size="sm"
                  ><span class="mr-50">Add Asset</span
                  ><feather-icon icon="ArrowRightCircleIcon"
                /></b-button>
              </div>
            </template>
          </empty-section>
        </template>
        <template v-else>
          <doughnut-assessment-chart
            :legend="legend"
            :series="series"
            @section-click="handleSectionClick"
        /></template>
      </div>
    </div>
  </b-overlay>
</template>

<script>
import DoughnutAssessmentChart from "../../components/DoughnutAssessmentChart.vue";
import { BOverlay, BButton } from "bootstrap-vue";
import EmptySection from "../../components/EmptySection.vue";
import ResponseMixins from "@/mixins/ResponseMixins";
import GeneralDashboardMixins from "@/mixins/GeneralDashboardMixins";

export default {
  mixins: [ResponseMixins, GeneralDashboardMixins],
  components: { DoughnutAssessmentChart, BOverlay, EmptySection, BButton },
  data() {
    return {
      totalValue: 0,
      showOverlay: false,
      legend: {
        orient: "vertical",
        left: 0,
        top: "start",
        formatter: function (name) {
          // Customize the legend text truncation
          const maxLength = 25; // Maximum length of legend text
          if (name.length > maxLength) {
            return name.substring(0, maxLength) + "..."; // Truncate text and add ellipsis
          }
          return name;
        },
      },

      series: [
        {
          name: "Risk Posture",
          type: "pie",
          radius: ["50%", "70%"], // Set the inner and outer radius for the doughnut chart
          center: ["60%", "50%"],
          data: [
            // { value: 0, name: "Low", itemStyle: { color: "#4CAF50" } }, // Green color for "Low"
            // { value: 0, name: "Medium", itemStyle: { color: "#FFC107" } }, // Yellow color for "Medium"
            // { value: 0, name: "High", itemStyle: { color: "#FF5722" } }, // Orange color for "High"
            // { value: 0, name: "Critical", itemStyle: { color: "#F44336" } }, // Red color for "Critical"
          ],
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: "rgba(0, 0, 0, 0.5)",
            },
          },
        },
      ],
    };
  },

  mounted() {
    this.load();
  },

  methods: {
    load() {
      this.getRiskPostureSummaryAndSetData({});
    },

    getRiskPostureSummaryAndSetData(params) {
      this.showOverlay = true;
      this.getGrcRiskPostureSummary(params)
        .then((res) => {
          const newSeriesData = [
            {
              value: res.data.data.low_risk_count,
              name: "Low",
              itemStyle: { color: "#28c76f" },
            }, // Green color for "Low"
            {
              value: res.data.data.medium_risk_count,
              name: "Medium",
              itemStyle: { color: "#ff9f43" },
            }, // Yellow color for "Medium"
            {
              value: res.data.data.high_risk_count,
              name: "High",
              itemStyle: { color: "#ea5455" },
            }, // Orange color for "High"
            {
              value: res.data.data.critical_risk_count,
              name: "Critical",
              itemStyle: { color: "#a020f0" },
            }, // Red color for "Critical"
          ];
          this.totalValue = newSeriesData.reduce(
            (accu, val) => accu + parseInt(val.value),
            0
          );
          this.$set(this.series[0], "data", newSeriesData);
        })
        .catch((err) => {
          console.log(err);
          this.handleError(err);
        })
        .finally(() => {
          this.showOverlay = false;
        });
    },

    handleSectionClick(data) {
      console.log("Clicked on section:", data);
      // Handle different sections based on the label
      switch (data.name) {
        case "Low":
          this.$router.push("/risk-register?risk_category=low");
          break;
        case "Medium":
          this.$router.push("/risk-register?risk_category=medium");
          break;
        case "High":
          this.$router.push("/risk-register?risk_category=high");
          break;
        case "Critical":
          this.$router.push("/risk-register?risk_category=critical");
          break;
        default:
          break;
      }
    },
  },
};
</script>

<style></style>
