<template>
  <div
    class="card rounded position-relative cursor-pointer mb-0"
    style="width: 240px"
    :style="{ border: `1px solid ${variant}` }"
    @click="
      () => {
        $router.push({
          path: '/risk-register',
          query: query,
        });
      }
    "
  >
    <div
      class="w-100 h-100"
      style="position: absolute; z-index: 1; opacity: 0.1"
      :style="{ 'background-color': variant }"
    ></div>
    <div
      class="card-header d-flex align-items-center justify-content-center py-50"
      style="z-index: 2"
    >
      <p class="font-weight-bold mb-0" :style="{ color: variant }">
        {{ title }}
      </p>
    </div>
    <div
      class="w-100 d-flex align-items-center justify-content-center"
      style="z-index: 2"
    >
      <h4 :style="{ color: variant }">{{ value }} / {{ total }}</h4>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },

    variant: {
      type: String,
      required: true,
    },

    total: {
      type: Number,
      required: true,
    },
    value: {
      type: Number,
      required: true,
    },
    query: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style>
</style>