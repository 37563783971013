<template>
  <div
    class="w-100 h-auto"
    v-if="
      $store.state.app.currentWorkspace &&
      $store.state.app.currentWorkspace != null
    "
  >
    <div
      class="w-100 d-flex flex-column align-items-center justify-content-start"
    >
      <section-1 />
      <div
        class="w-100 d-flex align-items-center justify-content-between mt-1"
        v-if="tenantModules.includes('GRC')"
      >
        <div style="width: 49%">
          <OrganizationRiskPosture />
        </div>
        <div style="width: 49%">
          <control-distribution />
        </div>
      </div>

      <div
        class="w-100 d-flex align-items-center justify-content-between mt-2"
        v-if="tenantModules.includes('GRC')"
      >
        <div style="width: 49%">
          <legal-registers />
        </div>
        <div style="width: 49%">
          <asset-vs-risk-distribution />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AssetVsRiskDistribution from "./sections/AssetVsRiskDistribution/AssetVsRiskDistribution.vue";
import ControlDistribution from "./sections/ControlDistribution/ControlDistribution.vue";
import LegalRegisters from "./sections/LegalRegisters/LegalRegisters.vue";
import OrganizationRiskPosture from "./sections/OrganizationRiskPosture/OrganizationRiskPosture";
import Section1 from "./sections/Section1/Section1.vue";

export default {
  components: {
    OrganizationRiskPosture,
    Section1,
    ControlDistribution,
    LegalRegisters,
    AssetVsRiskDistribution,
  },

  computed: {
    tenantModules() {
      return this.$store.state.app.currentWorkspace.enabled_modules.map(
        (m) => m.name
      );
    },
  },
};
</script>

<style></style>
