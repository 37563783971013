<template>
  <b-overlay
    :show="showOverlay"
    opacity="0.17"
    blur="1rem"
    rounded="md"
    variant="secondary"
  >
    <div class="card mb-0">
      <div class="card-header">
        <h2 class="card-title">Asset vs Risk Distribution</h2>
      </div>
      <div class="card-body cursor-pointer" style="height: 380px">
        <template v-if="totalValue === 0">
          <empty-section title="Data Empty">
            <template #content>
              <div
                class="w-100 d-flex flex-column align-items-center justify-content-center"
              >
                <p class="font-weight-bold text-center">
                  It seems like there are no Data at the moment.
                </p>
                <b-button
                  @click="
                    () => {
                      $router.push('/assets/add');
                    }
                  "
                  variant="success"
                  class="mt-50"
                  size="sm"
                  ><span class="mr-50">Add Asset</span
                  ><feather-icon icon="ArrowRightCircleIcon"
                /></b-button>
              </div>
            </template>
          </empty-section>
        </template>
        <template v-else>
          <div
            class="w-100 h-100"
          
          >
            <tm-chart-1
              :overall="chartData.overall"
              :critical="chartData.critical"
              :high="chartData.high"
              :medium="chartData.medium"
              :low="chartData.low"
            />
          </div>
        </template>
      </div>
    </div>
  </b-overlay>
</template>

<script>
import TmChart1 from "../../components/TmChart1.vue";
import { BOverlay, BButton } from "bootstrap-vue";
import EmptySection from "../../components/EmptySection.vue";
import ResponseMixins from '@/mixins/ResponseMixins';
import GeneralDashboardMixins from '@/mixins/GeneralDashboardMixins';

export default {
  mixins: [ResponseMixins, GeneralDashboardMixins],
  components: { TmChart1, BOverlay, EmptySection, BButton },
  data() {
    return {
      showOverlay: false,
      totalValue: 0,
      chartData: {
        overall: 0,
        critical: 0,
        high: 0,
        medium: 0,
        low: 0,
      },
    };
  },

  mounted() {
    this.load();
  },

  methods: {
    load() {
      this.getGrcHighRiskSummaryAndSetData({});
    },

    getGrcHighRiskSummaryAndSetData(params) {
      this.showOverlay = true;
      this.getGrcHighRiskSummary(params)
        .then((res) => {
          console.log("High Risk Summary ", res);
          const newChartData = {
            overall: parseInt(res.data.data.total_assets),
            critical: parseInt(res.data.data.critical_risk_assets_count),
            high: parseInt(res.data.data.high_risk_assets_count),
            medium: parseInt(res.data.data.medium_risk_assets_count),
            low: parseInt(res.data.data.low_risk_assets_count),
          };
          this.chartData = newChartData;
          this.totalValue = parseInt(res.data.data.total_assets);
        })
        .catch((err) => {
          console.log(err);
          this.handleError(err);
        })
        .finally(() => {
          this.showOverlay = false;
        });
    },
  },
};
</script>

<style></style>
