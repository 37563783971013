export default {

    methods: {
        getGapAssessmentsSummary(params) {
            console.log('Params',params)
            return new Promise((resolve, reject) => {
                const options = {
                    method: "GET",
                    url: `${process.env.VUE_APP_BASEURL}/dashboard/gap-assessment-summary`,
                    params: params,
                };
                this.$http(options)
                    .then(res => resolve(res))
                    .catch(err => reject(err))
            })
        },


        getTasksSummary(params) {
            console.log('Params',params)
            return new Promise((resolve, reject) => {
                const options = {
                    method: "GET",
                    url: `${process.env.VUE_APP_BASEURL}/dashboard/task-summary`,
                    params: params,
                };
                this.$http(options)
                    .then(res => resolve(res))
                    .catch(err => reject(err))
            })
        },


    }
}