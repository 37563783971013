<template>
  <div class="card">
    <div class="card-header pt-0">
      <div class="w-100 d-flex flex-column align-items-center justify-content-center">
        <b-badge variant="warning" class="mb-50">#{{ index }}</b-badge>
        <h4
          @click="
            () => {
              $router.push(`gap-assessment/${id}/details`);
            }
          "
          class="mb-0 cursor-pointer text-center"
        >
          {{ assessmentName }}
        </h4>
      </div>
    </div>
    <div
      class="d-flex align-items-center justify-content-center w-100"
      @click="
        () => {
          $router.push(`gap-assessment/${id}/details`);
        }
      "
    >
      <vue-apex-charts
        style="cursor: pointer"
        type="radialBar"
        height="410"
        :options="chartOptions"
        :series="series"
      ></vue-apex-charts>
    </div>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import { BBadge } from "bootstrap-vue";
export default {
  components: {
    VueApexCharts,
    BBadge,
  },

  props: {
    id: {
      type: String,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    series: {
      type: Array,
      required: true,
    },
    assessmentName: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      chartOptions: {
        chart: {
          height: 440,
          type: "radialBar",
        },
        plotOptions: {
          radialBar: {
            startAngle: -135,
            endAngle: 135,
            hollow: {
              margin: 0,
              size: "45%",
              background: "transparent",
              image: undefined,
            },
            dataLabels: {
              showOn: "always",
              name: {
                show: true,
                fontSize: "1.8rem",
              },
              value: {
                fontSize: "1.6rem",
                show: true,
              },
            },
          },
        },
        colors: ["#28c76f", "#ea5455"],
        labels: ["Progress", "Gap"],

        // fill: {
        //   type: "gradient",
        //   gradient: {
        //     shade: "dark",
        //     type: "horizontal",
        //     gradientToColors: ["#28c76f", "#ea5455"],
        //     stops: [20, 100],
        //   },
        // },
        stroke: {
          lineCap: "round",
        },

        legend: {
          show: true,
          floating: true,
          fontSize: "16px",
          position: "center",
          labels: {
            useSeriesColors: true,
          },
          markers: {
            size: 0,
          },
          formatter: function (seriesName, opts) {
            return seriesName + ":  " + opts.w.globals.series[opts.seriesIndex] + "%";
          },
          itemMargin: {
            vertical: 3,
          },
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              legend: {
                show: false,
              },
            },
          },
        ],
      },
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/chart-apex.scss";

.apexcharts-legend {
  width: 100% !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}
</style>
