<template>
  <div class="card w-100 mb-0 pb-0" style="height: 350px">
    <div
      class="card-header d-flex align-items-center justify-content-between"
    >
      <h4 class="mb-0 font-weight-bold">High Risk Assets</h4>
      <b-button
        size="sm"
        variant="primary"
        @click="
          () => {
            $router.push('/assets');
          }
        "
        >View All</b-button
      >
    </div>

    <div
      class="position-relative table-responsive mb-0"
      style="overflow-y: auto; height: 280px;"
    >
      <table role="table" class="table b-table table-striped table-hover">
        <thead role="rowgroup">
          <tr role="row">
            <th role="columnheader" scope="col">#</th>
            <th role="columnheader" scope="col">Name</th>
          </tr>
        </thead>
        <tbody role="rowgroup">
          <template v-if="summary && summary.length > 0">
            <tr
              v-for="(sum, index) in summary"
              :key="sum.asset_id"
              @click="
                () => {
                  $router.push(`/assets/${sum.asset_id}/details`);
                }
              "
            >
              <td style="width: 40px">{{ index + 1 }}</td>
              <td>{{ sum.asset_name }}</td>
            </tr>
          </template>

          <empty-table-section v-else title="Assets Not Found">
            <template #content>
              <p class="text-center">
                It seems like there are no high risk assets at the moment.
              </p>
            </template>
          </empty-table-section>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import ResponseMixins from "@/mixins/ResponseMixins";
import DashboardRiskAssessmentMixins from "@/mixins/DashboardRiskAssessmentMixins";
import EmptyTableSection from "@/components/EmptyTableSection.vue";
import { BButton } from "bootstrap-vue";
export default {
  components: { EmptyTableSection, BButton },
  mixins: [ResponseMixins, DashboardRiskAssessmentMixins],

  data() {
    return {
      summary: [],
    };
  },

  mounted() {
    this.load();
  },

  methods: {
    load() {
      this.getHighRiskAssetSummaryAndSetData();
    },

    getHighRiskAssetSummaryAndSetData(params) {
      this.getHighRiskAssetSummary(params)
        .then((res) => {
          console.log("Assets", res.data.data);
          this.summary = res.data.data.assets;
        })
        .catch((err) => {
          console.log(err);
          this.handleResponse(err);
        })
        .finally(() => {});
    },
  },
};
</script>

<style>
</style>