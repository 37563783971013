<template>
  <div class="position-relative table-responsive" style="min-height: 30vh">
    <table role="table" class="table b-table table-bordered table-hover">
      <thead role="rowgroup">
        <tr role="row">
          <th role="columnheader" scope="col">#</th>
          <th role="columnheader" scope="col">Name</th>
        </tr>
      </thead>
      <tbody role="rowgroup">
        <template v-if="risks && risks.length > 0">
          <tr
            v-for="(risk, index) in risks"
            :key="risk._id"
            @click="() => {
              $router.push({
                path: '/risk-register',
                query: {...query, risk_id:risk._id, search:risk.name},
              });
            }"
          >
            <td style="width: 40px">{{ index + 1 }}</td>
            <td>{{ risk.name }}</td>
          </tr>
          <tr>
            <td colspan="2">
              <div class="w-100 d-flex align-items-center justify-content-end">
                <b-button
                  size="sm"
                  variant="primary"
                  @click="
                    () => {
                      $router.push({
                        path: '/risk-register',
                        query: query,
                      });
                    }
                  "
                  >View More</b-button
                >
              </div>
            </td>
          </tr>
        </template>

        <template v-else>
          <empty-table-section title="Risks Not Found">
            <template #content>
              <p class="text-center">
                It seems like there are no risks of this type at the moment.
              </p>
            </template>
          </empty-table-section>
        </template>
      </tbody>
    </table>
  </div>
</template>

<script>
import EmptyTableSection from "@/components/EmptyTableSection.vue";
import { BButton } from "bootstrap-vue";
export default {
  components: { EmptyTableSection, BButton },
  props: {
    risks: {
      type: Array,
      required: true,
    },
    query: {
      type: String,
      required: true,
    },
  },

  methods: {
    handleRiskClick(risk) {
      console.log('Risk Details: ', risk)
      this.$router.push({
        name: "risk-details",
        params: { riskId: risk._id, registerId: risk.risk_id },
      });
    },
  },
};
</script>

<style></style>
