<template>
  <div class="card mb-0 pb-25" style="height: 350px">
    <div
      class="card-header d-flex align-items-center justify-content-center mb-1 pb-0"
    >
      <h4 class="text-center font-weight-bold mb-0">Risk Mitigation</h4>
    </div>

    <div class="card-body">
      <div class="d-flex flex-column align-items-center justify-content-center">
        <div
          class="d-flex align-items-center justify-content-center w-100 mb-2"
        >
          <div class="d-flex flex-column flex-wrap text-center col-sm-2">
            <h1 class="font-large-2 font-weight-bolder mb-0 text-center text-primary">
              {{ summary.totalRisksCount || 0 }}
            </h1>
            <h6 class="text-center font-weight-bold text-primary">Total</h6>
          </div>
        </div>

        <div class="d-flex align-items-center justify-content-center w-100">
          <div class="mx-1">
            <risk-mitigation-item
              variant="primary"
              title="Unattended"
              :value="summary.unattendedRisksCount || 0"
              :query="{ risk_mitigation_type: 0 }"
            />
          </div>
          <div class="mx-1">
            <risk-mitigation-item
              variant="secondary"
              title="Transferred"
              :value="summary.transferredRisksCount || 0"
              :query="{ risk_mitigation_type: 2 }"
            />
          </div>

          <div class="mx-1">
            <risk-mitigation-item
              variant="danger"
              title="Avoided"
              :value="summary.avoidedRisksCount || 0"
              :query="{ risk_mitigation_type: 1 }"
            />
          </div>

          <div class="mx-1">
            <risk-mitigation-item
              variant="warning"
              title="Mitigated"
              :value="summary.mitigatedRisksCount || 0"
              :query="{ risk_mitigation_type: 4 }"
            />
          </div>

          <div class="mx-1">
            <risk-mitigation-item
              variant="success"
              title="Accepted"
              :value="summary.acceptedRisksCount || 0"
              :query="{ risk_mitigation_type: 3 }"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DashboardRiskAssessmentMixins from "@/mixins/DashboardRiskAssessmentMixins";
import RiskMitigationItem from "./components/RiskMitigationItem.vue";
import ResponseMixins from "@/mixins/ResponseMixins";
export default {
  mixins: [DashboardRiskAssessmentMixins, ResponseMixins],
  components: { RiskMitigationItem },

  mounted() {
    this.load();
  },

  data() {
    return {
      summary: {},
    };
  },

  methods: {
    load() {
      this.getRiskMitigationSummaryAndSetData();
    },

    getRiskMitigationSummaryAndSetData(params) {
      this.getRiskMitigationSummary(params)
        .then((res) => {
          console.log(res.data.data);
          this.summary = res.data.data;
        })
        .catch((err) => {
          console.log(err);
          this.handleError(err);
        })
        .finally(() => {});
    },
  },
};
</script>

<style>
</style>