<template>
  <div class="position-relative table-responsive" style="min-height: 50vh">
    <b-overlay
      :show="showOverlay"
      opacity="0.17"
      blur="1rem"
      rounded="md"
      variant="secondary"
    >
      <table role="table" class="table">
        <thead role="rowgroup">
          <tr role="row">
            <th scope="col">#</th>
            <th scope="col">Name</th>
            <th scope="col">Status</th>
            <th scope="col">Priority</th>
            <th scope="col">Created At</th>
          </tr>
        </thead>
        <tbody role="rowgroup">
          <template v-if="tasks && tasks.length > 0">
            <tr
              class="cursor-pointer"
              v-for="(task, index) in tasks"
              :key="task._id"
              @click="
                () => {
                  $router.push(`/tasks?task_id=${task._id}`);
                }
              "
            >
              <th>
                {{ index + 1 }}
              </th>

              <td role="cell" style="max-width: 25vw">
                <div
                  class="d-flex align-items-center justify-content-start w-100"
                >
                  <p class="text-left font-weight-bold mb-0">
                    {{ task.title }}
                  </p>
                </div>
              </td>

              <td aria-colindex="2" role="cell">
                <b-badge :variant="getStatusVariant(task.status)">{{
                  task.status | statusText
                }}</b-badge>
              </td>

              <td aria-colindex="2" role="cell">
                <b-badge :variant="getPriorityVariant(task.priority)">{{
                  task.priority | priorityText
                }}</b-badge>
              </td>

              <td
                aria-colindex="2"
                role="cell"
                style="max-width: 6vw; padding-right: 0px"
              >
                <div class="d-flex justify-content-start w-100">
                  <p class="mb-0 font-weight-bold">
                    {{ task.created_at | moment }}
                  </p>
                </div>
              </td>
            </tr>
          </template>
          <template v-else>
            <empty-table-section title="Tasks Not Found">
              <template #content>
                <p class="font-weight-bold text-center">
                  It seems like there are no tasks at the moment.
                </p>
              </template>
            </empty-table-section>
          </template>
        </tbody>
      </table>
    </b-overlay>
  </div>
</template>

<script>
import ResponseMixins from "@/mixins/ResponseMixins";
import DashboardGapAssessmentMixins from "@/mixins/DashboardGapAssessmentMixins";
import moment from "moment";
import { BBadge } from "bootstrap-vue";
import EmptyTableSection from "@/components/EmptyTableSection.vue";

export default {
  mixins: [ResponseMixins, DashboardGapAssessmentMixins],
  components: { BBadge, EmptyTableSection },

  data() {
    return {
      showOverlay: false,
      tasks: [],
    };
  },
  mounted() {
    this.load();
  },

  filters: {
    moment: function (date) {
      return moment(date).format("D-MMM-YY");
    },

    statusText: function (num) {
      switch (num) {
        case 1:
          return "Pending";
        case 2:
          return "In Progress";
        case 3:
          return "In Review";
        case 4:
          return "Approved";
        case 5:
          return "Rejected";
      }
    },

    priorityText: function (num) {
      switch (num) {
        case 1:
          return "Low";
        case 2:
          return "Medium";
        case 3:
          return "High";
        default:
          return "Low";
      }
    },
  },

  methods: {
    load() {
      this.getTasksSummaryAndSetData({ limit: 5, status: "closed" });
    },
    getTasksSummaryAndSetData(params) {
      this.showOverlay = true;
      this.getTasksSummary(params)
        .then((res) => {
          this.tasks = res.data.data.tasks.data;
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
          this.handleError(err);
        })
        .finally(() => {
          this.showOverlay = false;
        });
    },

    getStatusVariant(num) {
      switch (num) {
        case 1:
          return "secondary";
        case 2:
          return "primary";
        case 3:
          return "warning";
        case 4:
          return "success";
        case 5:
          return "danger";
        default:
          return "info";
      }
    },

    getPriorityVariant(num) {
      switch (num) {
        case 1:
          return "success";
        case 2:
          return "warning";
        case 3:
          return "danger";
        default:
          return "success";
      }
    },
  },
};
</script>

<style>
</style>