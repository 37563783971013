<template>
  <div class="w-100 h-auto">
    <b-overlay
      opacity="0.17"
      blur="1rem"
      :show="showOverlay"
      rounded="md"
      variant="secondary"
    >
      <div class="mb-0">
        <div class="card mb-0">
          <div class="card-body">
            <div class="d-flex align-items-center justify-content-center pb-0">
              <h4 class="text-center text-left font-weight-bold mb-1">
                Organization Risk Posture
              </h4>
            </div>
            <div class="d-flex align-items-start justify-content-between w-100">
              <div class="card shadow-none bg-transparent" style="width: 48%">
                <div class="card-body" style="height: 55vh">
                  <doughnut-assessment-chart
                    :legend="legend"
                    @tab-change="handleTabChange"
                    :series="series"
                  />
                </div>
              </div>

              <!-- <div class="w-50">
              <div
                class="d-flex align-items-center justify-content-between w-100 m-1"
              >
                <h4 class="text-start text-primary font-weight-bold mb-0">
                  Top 3 Risks
                </h4>
                <b-button
                  size="sm"
                  variant="primary"
                  @click="
                    () => {
                      $router.push('/risk-register');
                    }
                  "
                  >View All</b-button
                >
              </div>
            </div> -->

              <div
                class="card shadow-none bg-transparent"
                style="width: 48%; height: 55vh"
              >
                <div class="card-body">
                  <div class="w-100 h-100">
                    <div
                      class="d-flex align-items-start justify-content-between w-100 mt-1 mx-1"
                    >
                      <h5 class="text-start text-primary font-weight-bold mb-0">
                        Top 3 Risks
                      </h5>
                      <b-button
                        size="sm"
                        variant="primary"
                        @click="
                          () => {
                            $router.push('/risk-register');
                          }
                        "
                        >View All</b-button
                      >
                    </div>
                    <b-tabs v-model="activeTab" justified>
                      <b-tab lazy>
                        <template #title>
                          <span class="critical-tab">Critical</span>
                        </template>
                        <risks-table
                          :risks="summary.critical_risks"
                          :query="{ risk_category: 'critical' }"
                        />
                      </b-tab>

                      <b-tab lazy>
                        <template #title>
                          <span class="high-tab">High</span>
                        </template>
                        <risks-table
                          :risks="summary.high_risks"
                          :query="{ risk_category: 'high' }"
                        />
                      </b-tab>

                      <b-tab lazy>
                        <template #title>
                          <span class="medium-tab">Medium</span>
                        </template>
                        <risks-table
                          :risks="summary.medium_risks"
                          :query="{ risk_category: 'medium' }"
                        />
                      </b-tab>

                      <b-tab lazy>
                        <template #title>
                          <span class="low-tab">Low</span>
                        </template>
                        <risks-table
                          :risks="summary.low_risks"
                          :query="{ risk_category: 'low' }"
                        />
                      </b-tab>
                    </b-tabs>
                  </div>
                </div>
              </div>
            </div>

            <div class="d-flex align-items-start justify-content-between w-100">
              <risk-item
                title="Critical"
                :total="seriesTotal"
                :value="summary.critical_risk_count"
                variant="#a020f0"
                :query="{ risk_category: 'critical' }"
              />
              <risk-item
                title="High"
                :total="seriesTotal"
                :value="summary.high_risk_count"
                variant="#ea5455"
                :query="{ risk_category: 'high' }"
              />
              <risk-item
                title="Medium"
                :total="seriesTotal"
                :value="summary.medium_risk_count"
                variant="#ff9f43"
                :query="{ risk_category: 'medium' }"
              />
              <risk-item
                title="Low"
                :total="seriesTotal"
                :value="summary.low_risk_count"
                variant="#28c76f"
                :query="{ risk_category: 'low' }"
              />
            </div>
          </div>
        </div>
      </div>
    </b-overlay>

    <div class="d-flex align-items-start justify-content-between w-100 mt-2">
      <div style="max-width: 70%" class="h-auto mr-2">
        <risk-mitigation />
      </div>

      <div class="card mb-0 h-100" style="width:100%;">
        <!-- <div
          class="card-header d-flex align-items-center justify-content-center pb-0"
        >
          <h2 class="text-center font-weight-bold mb-0">Assets</h2>
        </div> -->

        <div
          class="w-100 d-flex flex-column align-items-start justify-content-start h-100"
        >
          <high-risk-assets />
        </div>
      </div>
    </div>
    <div class="mt-2">
      <major-vulnerability-or-threats />
    </div>
  </div>
</template>

<script>
import DoughnutAssessmentChart from "../../components/DoughnutAssessmentChart.vue";
import { BTabs, BTab, BCardText, BOverlay, BButton } from "bootstrap-vue";
import RisksTable from "./components/RisksTable.vue";
import RiskItem from "./components/RiskItem.vue";
import RiskMitigation from "./RiskMitigation.vue";
import HighRiskAssets from "./HighRiskAssets.vue";
import MajorVulnerabilityOrThreats from "./MajorVulnerabilityOrThreats.vue";
import ResponseMixins from "@/mixins/ResponseMixins";
import DashboardRiskAssessmentMixins from "@/mixins/DashboardRiskAssessmentMixins";

export default {
  mixins: [ResponseMixins, DashboardRiskAssessmentMixins],
  components: {
    DoughnutAssessmentChart,
    BTabs,
    BTab,
    BCardText,
    RisksTable,
    RiskItem,
    RiskMitigation,
    HighRiskAssets,
    MajorVulnerabilityOrThreats,
    BOverlay,
    BButton,
  },

  data() {
    return {
      activeTab: 0,
      summary: {},
      showOverlay: false,
      seriesTotal: 0,
      legend: {
        orient: "vertical",
        left: 0,
        top: "start",
        formatter: function (name) {
          // Customize the legend text truncation
          const maxLength = 25; // Maximum length of legend text
          if (name.length > maxLength) {
            return name.substring(0, maxLength) + "..."; // Truncate text and add ellipsis
          }
          return name;
        },
      },
      series: [
        {
          name: "Risk Posture",
          type: "pie",
          radius: ["50%", "70%"], // Set the inner and outer radius for the doughnut chart
          center: ["60%", "50%"],
          data: [
            // { value: 0, name: "Low", itemStyle: { color: "#4CAF50" } }, // Green color for "Low"
            // { value: 0, name: "Medium", itemStyle: { color: "#FFC107" } }, // Yellow color for "Medium"
            // { value: 0, name: "High", itemStyle: { color: "#FF5722" } }, // Orange color for "High"
            // { value: 0, name: "Critical", itemStyle: { color: "#F44336" } }, // Red color for "Critical"
          ],
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: "rgba(0, 0, 0, 0.5)",
            },
          },
        },
      ],
    };
  },
  mounted() {
    this.load();
  },
  methods: {
    load() {
      this.getRiskPostureSummaryAndSetData();
    },

    handleTabChange(name) {
      console.log(name);
      this.activeTab = this.mapTabName(name);
    },

    getRiskPostureSummaryAndSetData() {
      this.showOverlay = true;
      this.getRiskPostureSummary({ limit: 3 })
        .then((res) => {
          console.log(res);
          this.summary = res.data.data;
          this.seriesTotal =
            res.data.data.low_risk_count +
            res.data.data.medium_risk_count +
            res.data.data.high_risk_count +
            res.data.data.critical_risk_count;
          const newSeriesData = [
            {
              value: res.data.data.low_risk_count,
              name: "Low",
              itemStyle: { color: "#28c76f" },
            }, // Green color for "Low"
            {
              value: res.data.data.medium_risk_count,
              name: "Medium",
              itemStyle: { color: "#ff9f43" },
            }, // Yellow color for "Medium"
            {
              value: res.data.data.high_risk_count,
              name: "High",
              itemStyle: { color: "#ea5455" },
            }, // Orange color for "High"
            {
              value: res.data.data.critical_risk_count,
              name: "Critical",
              itemStyle: { color: "#a020f0" },
            }, // Red color for "Critical"
          ];
          this.$set(this.series[0], "data", newSeriesData);
        })
        .catch((err) => {
          console.log(err);
          this.handleError(err);
        })
        .finally(() => {
          this.showOverlay = false;
        });
    },

    mapTabName(name) {
      switch (name) {
        case "Critical":
          return 0;

        case "High":
          return 1;

        case "Medium":
          return 2;

        case "Low":
          return 3;
      }
    },
  },
};
</script>

<style scoped>
/* Define custom styles for tab navs */
.low-tab {
  color: #28c76f;
}

.medium-tab {
  color: #ff9f43;
}

.high-tab {
  color: #ea5455;
}

.critical-tab {
  color: #a020f0;
}
</style>
