<template>
  <e-charts
    ref="line"
    autoresize
    :options="option"
    theme="theme-color"
    auto-resize
    @click="handleChartClick"
  />
</template>

<script>
import ECharts from "vue-echarts";
import "echarts/lib/component/tooltip";
import "echarts/lib/component/legend";
import "echarts/lib/chart/pie";
import theme from "@core/components/charts/echart/theme.json";

ECharts.registerTheme("theme-color", theme);

export default {
  components: {
    ECharts,
  },
  props: {
    series: {
      type: Array,
      default: null,
    },

    legend: {
      type: Object,
      default: null,
      required: false,
    },
  },
  data() {
    return {
      option: {
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b}: {c} ({d}%)",
        },
        legend: {
          orient: "vertical",
          left: "left",
        },
        series: this.series,
      },
    };
  },

  created() {
    this.$set(this.option, "legend", this.legend);
  },

  methods: {
    handleChartClick(event) {
      this.$emit("section-click", event.data);
    },
  },
};
</script>

<style scoped>
.echarts {
  width: inherit;
  height: inherit;
  position: relative;
}
</style>
