<template>
  <b-card no-body class="shadow-none m-0 rounded-none h-100">
    <!-- <b-card-header class="pb-0">
      <b-card-title class="text-primary">Overall</b-card-title>
    </b-card-header> -->

    <b-card-body
      class="m-0 py-0 d-flex flex-column align-items-center justify-content-between h-100"
    >
      <div class="w-100 d-flex align-items-start justify-content-between">
        <div
          class="d-flex flex-column align-items-center justify-content-center text-start h-100"
          style="width: 160px"
        >
          <b-card-title class="text-primary mb-1 text-center"
            >Overall</b-card-title
          >
          <h1
            class="font-large-2 font-weight-bolder mb-0 cursor-pointer text-center"
            @click="
              $router.push({
                path: '/assets/',
              })
            "
          >
            {{ overall }}
          </h1>
          <p class="mb-0 mt-25 text-center">Total Managed Assets</p>
        </div>
        <div class="d-flex align-items-start justify-content-center w-100">
          <vue-apex-charts
            type="radialBar"
            height="310"
            :options="supportTrackerRadialBar.chartOptions"
            :series="series"
          />
        </div>
      </div>

      <!-- chart info -->
      <div class="w-100 d-flex align-items-center justify-content-between mt-1">
        <div class="text-center mr-1">
          <b-card-text class="mb-50" style="color: #a020f0"
            >Critical</b-card-text
          >
          <span
            class="font-large-1 font-weight-bold cursor-pointer"
            style="color: #a020f0"
            @click="
              $router.push({
                path: '/assets',
                query: { risk_value_category: 'critical' },
              })
            "
            >{{ critical }}</span
          >
        </div>
        <div class="text-center mr-1">
          <b-card-text class="mb-50 text-danger">High</b-card-text>
          <span
            class="font-large-1 font-weight-bold cursor-pointer text-danger"
            @click="
              $router.push({
                path: '/assets',
                query: { risk_value_category: 'high' },
              })
            "
            >{{ high }}</span
          >
        </div>
        <div class="text-center mr-1">
          <b-card-text class="mb-50 text-warning">Medium</b-card-text>
          <span
            class="font-large-1 font-weight-bold cursor-pointer text-warning"
            @click="
              $router.push({
                path: '/assets',
                query: { risk_value_category: 'medium' },
              })
            "
            >{{ medium }}</span
          >
        </div>
        <div class="text-center mr-1">
          <b-card-text class="mb-50 text-success">Low</b-card-text>
          <span
            class="font-large-1 font-weight-bold cursor-pointer text-success"
            @click="
              $router.push({
                path: '/assets',
                query: { risk_value_category: 'low' },
              })
            "
            >{{ low }}</span
          >
        </div>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BDropdown,
  BDropdownItem,
  BCardBody,
  BRow,
  BCol,
  BCardText,
} from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import { $themeColors } from "@themeConfig";

export default {
  components: {
    VueApexCharts,
    BCard,
    BCardHeader,
    BCardTitle,
    BDropdown,
    BDropdownItem,
    BCardText,
    BCardBody,
    BRow,
    BCol,
  },
  props: {
    overall: {
      type: Number,
      required: true,
    },
    critical: {
      type: Number,
      required: true,
    },
    high: {
      type: Number,
      required: true,
    },
    medium: {
      type: Number,
      required: true,
    },
    low: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      supportTrackerRadialBar: {
        series: [50],
        chartOptions: {
          plotOptions: {
            radialBar: {
              size: 150,
              offsetY: 20,
              startAngle: -150,
              endAngle: 150,
              hollow: {
                size: "65%",
              },
              track: {
                background: "#fff",
                strokeWidth: "100%",
              },
              dataLabels: {
                name: {
                  offsetY: -5,
                  color: "#5e5873",
                  fontSize: "1rem",
                },
                value: {
                  offsetY: 15,
                  color: "#5e5873",
                  fontSize: "1.714rem",
                },
              },
            },
          },
          colors: [$themeColors.danger],
          fill: {
            type: "gradient",
            gradient: {
              shade: "dark",
              type: "horizontal",
              shadeIntensity: 0.5,
              gradientToColors: [$themeColors.primary],
              inverseColors: true,
              opacityFrom: 1,
              opacityTo: 1,
              stops: [0, 100],
            },
          },
          stroke: {
            dashArray: 8,
          },
          labels: ["Risky Assets"],
        },
      },
    };
  },

  computed: {
    // a computed getter
    // +
    //       this.overallrisk.overall_asset_data.risk_profile.medium_risk_assets +
    //       this.overallrisk.overall_asset_data.risk_profile.low_risk_assets
    series: function () {
      const totalAssets = this.overall;
      let c = 0;
      if (totalAssets !== 0) {
        c = Math.round(((this.critical + this.high) / this.overall) * 100);
      }
      console.log("c", c);
      return [c];
    },
  },
  methods: {},
};
</script>
